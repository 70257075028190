#seagovNavbar {
    button {
        padding: 0;
        border: none;
        background-color: transparent;
        outline-color: $global_color_white;

        &:hover {
            cursor: pointer;
        }
        
        .navbar-button-text {
            font-family: "Open Sans";
            font-size: 18px;
            font-weight: bold;
        }
    }
    
    #mainMenuToggle {
        margin-right: 10px;

        @media (max-width: 767px) {

            float: right;
            padding-right: 20px;
            text-align: center;
            margin: 9px 0;
        
            svg {
                margin-right: 0px;
                font-size: 24px;
                display: block;
                margin: 0 auto;
            }
        
            .navbar-button-text {
                display: block;
                font-size: 12px;
            }
        }
    }
}

/* styles that apply to both tablet and mobile */
@media (max-width: 991px) {
    /* slide body with menu */
    div#content.slidemenu-open {
        position: relative;
        right: 260px;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.3s ease;
        transition: all 0.4s ease;
    }

    .contentOverlay {
        background-color: rgba(0,0,0,.7);
        position: absolute;
        z-index: 300;
        width: 100%;
        height: 100%;
    }
    /* search mobile */
    #searchInput_M {
        width: 100px;
        height: 25px;
        border: none;
        font-size: 12px;
        border-radius: 0;
    }

    #googleSearch_M {
        width: 100px;
        margin: 0;
    }

    #googleSearch_M ::-webkit-input-placeholder {
        font-size: $search_fontSize_google;
    }

    #googleSearch_M ::-webkit-input-placeholder:after {
        font-size: $search_fontSize_google;
    }

    #googleSearch_M :-moz-placeholder {
        font-size: $search_fontSize_google;
    }

    #googleSearch_M :-moz-placeholder:after {
        font-size: $search_fontSize_google;
    }

    #googleSearch_M ::-moz-placeholder {
        font-size: $search_fontSize_google;
    }

    #googleSearch_M ::-moz-placeholder:after {
        font-size: $search_fontSize_google;
    }

    #googleSearch_M :-ms-input-placeholder {
        font-size: $search_fontSize_google;
    }

    #googleSearch_M :-ms-input-placeholder:after {
        font-size: $search_fontSize_google;
    }
    /* search toggles mobile*/
    #googleSearchToggle_M {
        display: none;
    }

    /* top menu */
    #seagovMenuDesktop {
        display: none;
    }

    #backReturn span {
        font-size: $global_fontSize_default;
    }

    #backReturn span.fa {
        padding: 20px;
    }

    .seagovHeaderLinksMobile {
        display: block;
    }

    #currentMenuContext li.active > a {
        font-size: 18px;
        padding: 18px 0px 18px 20px;
    }

    /* Current Context Menu */
    li#currentMenuContextWrapper {
        border: 0px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    ul#currentMenuContext {
        padding-left: 0px;
    }

    #currentMenuContext ul {
        padding-left: 0px;
    }
    /*Dept top Nav*/
    #deptTopNav {
        display: none;
    }

    #searchIcon {
        display: block;
    }

    /*Mobile Menu*/
    #seagovMenuMobile,
    #slideMenuNav ul li {
        width: 100%;
    }

    #seagovMenuMobile li.previous ul {
        margin-left: -43px;
    }

    /*Close*/
    // #seagovMenuMobile #mainMenuCloseMobile {
    //     background: transparent !important;
    //     color: white
    // }

    // #seagovMenuMobile #mainMenuCloseMobile:hover {
    //     background: $global_color_white !important;
    // }

    #seagovMenuMobile #mainMenuCloseMobile i {
        color: $global_color_white !important;
        font-size: 2.25rem;
    }
    /* Fontawesome v5 */
    #seagovMenuMobile #mainMenuCloseMobile svg {
        color: $global_color_white !important;
        font-weight: 300;
        margin: 0 6px;
        vertical-align: middle;
        width: 13px;
        height: 20px;
        position: relative;
        top: -2px;
    }

    /*#seagovMenuMobile #mainMenuCloseMobile:hover i {
        color: $global_color_black !important;
    }
     Fontawesome v5 
    #seagovMenuMobile #mainMenuCloseMobile:hover svg {
        color: $global_color_black !important;
    }*/

    /*Search Form*/
    #seagovMenuMobile #googleSearch_M {
        padding: 0px;
        width: 100%;
        border: 0px;
    }
    /*Search Input*/
    #seagovMenuMobile #googleSearch_M .input-group {
        display: block;
        background: $global_color_darkGray !important;
        width: 100% !important;
        height: 60px;
    }

    #seagovMenuMobile #googleSearch_M .input-group input[type="text"] {
        position: absolute;
        display: block;
        margin: 10px 10px 10px 20px;
        width: 220px !important;
        height: 40px;
        box-sizing: border-box;
        background-color: $global_color_transparent;
        border: 1px solid $global_color_mediumGray;
        color: $global_color_white;
        font-size: 18px;
    }

    #seagovMenuMobile #googleSearch_M ::-webkit-input-placeholder {
        color: #f2f2f2;
        background: $global_color_transparent;
        padding: 0px;
        line-height: 24px;
        font-size: $global_fontSize_default;
        font-weight: 600;
    }

    #seagovMenuMobile #googleSearch_M ::-webkit-input-placeholder:after {
        content: "";
    }

    /*Search Button*/
    #seagovMenuMobile #googleSearch_M .input-group-btn {
        right: 0px !important;
        float: right;
    }

    #seagovMenuMobile #googleSearch_M .input-group-btn #searchButton_M, #searchCollectionMobileToggle {
        position: absolute;
        display: block;
        z-index: 20;
        right: 50px;
        float: right;
        width: 40px;
        height: 40px;
        text-align: center;
        background: #333;
        border-top: 1px solid $global_color_mediumGray;
        border-right: 1px solid $global_color_mediumGray;
        border-bottom: 1px solid $global_color_mediumGray;
        border-radius: 0px;
    }

    button#searchCollectionMobileToggle {
        right: 11px;
    }

    button#searchCollectionMobileToggle:hover {
        border-top: 1px solid $global_color_mediumGray;
        border-bottom: 1px solid $global_color_mediumGray;
    }

    button#searchCollectionMobileToggle:hover i {
        color: $global_color_white;
    }

    #seagovMenuMobile #googleSearch_M .input-group-btn #searchButton_M:hover, #searchCollectionMobileToggle:hover {
        background: $global_color_black;
    }

    #seagovMenuMobile #googleSearch_M .input-group-btn #searchButton_M i, button#searchCollectionMobileToggle i {
        font-size: 20px;
        padding: 2px 2px 0px 0px;
        color: #f2f2f2;
    }

    #seagovMenuMobile #googleSearch_M .input-group-btn #searchButton_M:hover i {
        color: $global_color_white;
    }

    /*active*/
    #currentMenuContext li.active {
        //background: $global_color_black;
        border: 0px;
        border-bottom: 1px solid #d1d1d1;
        margin-bottom: 0px;
    }

    /*All other nav links*/
    #seagovMenuMobile .navItem.list-group-item {
        border: 0px;
        border-bottom: 1px solid #d1d1d1;
        margin-bottom: 0px;
    }

    #seagovMenuMobile .navItem a .fa-chevron-right, #seagovMenuMobile .navItem.utility a.expanded:after, 
    #seagovMenuMobile .navItem.utility a.collapsed:after {
        display: inline-block;
        position: absolute;
        right: 20px;
    }

    #seagovMenuMobile .seagovHeaderLinksMobile.list-group-item.navItem {
        list-style: none;
    }
}

@media (max-width: 885px) {
    /*Loop 11 Mobile Fix*/
    div#loop11-showbuttonwrapper,
    div#loop11-slider {
        position: fixed !important;
    }

    div#loop11-showbuttonwrapper,
    div#loop11-slider.loop11-sideposition {
        top: inherit !important;
        bottom: 1% !important;
    }
}


@media (max-width: 767px) {
    #seagovNavbar .navbar-brand {
        padding: 5px 15px;
    }

    .seattleMayor:after {
        margin-left: -115px;
    }

    .navbar-toggle {
        margin-top: 8px;
    }

    /*
    Department header styles need to be this explicit.
    Or they'll get overidden by responsive.css & others
    */
    #deptTitle {
        padding: 0px;
        margin: 10px 0px 7px 0px;
        font-family: $global_fontFamily_headers;
        font-size: 32px;
        line-height: 32px;
    }

    #deptTitle a {
        font-size: $seaGov_fontSize_header;
        line-height: 22px;
        display: inline-block;
    }

    .director {
        font-size: 13px;
        margin-top: -10px;
        margin-bottom: 0px;
    }

    #deptBreadCrumbsWrapper {
        display: none;
    }

    #seagovBanner.High-Alert.departmentAlertBanner {
        text-align: center;
    }

    .departmentAlertBanner .closeBannerBtn {
        top: 10%;
    }

    #seagovBanner.departmentAlertBanner .fa-exclamation-triangle {
        position: relative;
    }
}

/* desktops */
@media (min-width: 992px) {
    /* top menu */
    #seagovMenuMobile {
        display: none !important;
    }
}

@media (min-width: 768px) {
    #header {
        padding-left: 0px;
        padding-right: 0px;
    }

    #header .container {
        padding-right: 0px;
        padding-left: 0px;
    }

    /* primary navigation bar */
    #seagovNavbar {
        width: 100%;
    }

    .navbar-nav {
        float: left;
        margin: 0;
        max-width: 78%;
    }

    .navbar-nav > li {
        max-width: 16%;
    }

    .navbar-nav > li:nth-child(5) {
        max-width: 18%;
    }

    #siteFooter {
        display: flexbox;
    }

    .director {
        margin-top: -5px;
        margin-bottom: 20px;
    }
}
/* end min-width 768 */


#seagovBanner.Normal {
    display: none;
}

#seagovBanner.Normal.departmentAlertBanner {
    display: block;
}

@media (max-width: 768px) {
    /* tablets and mobile phones */
    #searchModal {
        background-color: #e8e8e8;
    }

    #searchModal .modal-dialog {
        margin: 0;
    }

    #searchModal .modal-content {
        background-color: #e8e8e8;
        border-color: #C8C8C8;
        color: #b2b2b2;
    }
    /*modal's close icon*/
    #searchModal .fa-xmark {
        color: $global_color_black;
        font-weight: normal;
    }

    /*search textbox w icon*/
    #googleSearch {
        margin-top: 0px;
        width: 100%;
    }

    #googleSearch ::placeholder {
        font-weight: bold;
        color: #b2b2b2;
        width: 100%;
    }

    #googleSearch #searchInput {
        width: 100% !important;
        border-color: #c8c8c8;
        border-width: 2px;
        border-style: solid;
        border-right: none;
        color: #5f5f5f !important;
        font-weight: normal;
        /*border-radius: 4px 0 0 4px; */
    }

    #googleSearch #eac-container-searchInput {
        width: 100% !important;
    }

    /*box around search icon*/
    button#searchButton {
        color: #5f5f5f;
        background-color: $global_color_white;
        font-weight: normal;
        border-color: #c8c8c8;
        border-width: 2px;
        border-style: solid;
        border-left: none;
        /* border-radius: 0 4px 4px 0; */
    }

    /* search icon */
    #googleSearch #searchButton .fa-magnifying-glass {
        color: #5f5f5f;
        background-color: $global_color_white;
        border: none;
        font-weight: normal;
    }

    /* top menu */
    #searchIcon {
        cursor: pointer;
        padding-right: 15px !important;

        .navbar-button-text {
            display: none;
        }
    }

    /* this removes the shadow on the modal */
    .modal-content {
        -webkit-box-shadow: 0 5px 15px rgba(0,0,0,0);
        -moz-box-shadow: 0 5px 15px rgba(0,0,0,0);
        -o-box-shadow: 0 5px 15px rgba(0,0,0,0);
        box-shadow: 0 5px 15px rgba(0,0,0,0);
        border: none;
    }


    #googleSearch #searchButton:hover {
        background: $global_color_white;
        border-color: #c8c8c8;
        border-width: 2px;
        border-style: solid;
        border-left: none;
    }

    .easy-autocomplete {
        width: 100%;
    }
}

#translate {
    position: relative; 
    //right: 560px;
    top: 2px;
    height: 24px;
    font-size: 14px;
    background-color: $global_color_white;
    z-index: 102;
    border: solid 1px $global_color_primaryBlue;

    .translate__dropdown {  
        border: solid 1px $global_color_primaryBlue;
        background-color: $global_color_white;
        min-width: 160px;
        max-width: 200px;
        display: table; 
    } 
    
    .translate__label {
        width: 100%;
        text-align: right;
        cursor: pointer;
        display: block;
        background-color: $global_color_white;
        padding-left: 5px;

        svg {
            color: $global_color_black;
            position: relative;
            top: 2px;
            margin: 0 5px 0 5px;
        }
    }

    .translate__dropdown.expanded {
        padding-bottom: 10px;
    }
    
    .translate__link {
        cursor: pointer;
        color: $global_color_primaryBlue;
    }

    .menu--lang {
        list-style: none;
        margin: 10px 0 0 0;
        float: left;
        position: relative;
        z-index: 2;
        width: 100%;

        li {
            a {
                position: relative;
                left: -30px;
            }
        }
    }
    
    .menu__item {
        margin: 5px 0;
    }

    .translate__disclaimer {
        float: right;
        position: absolute;
        top: 22px;
        white-space: nowrap;
        text-align: right;
        width: 100%;

        a {
            color: $global_color_white;
            font-size: 12px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    @media (min-width: 576px) and (max-width: 767px) {
        margin-right: 30px;
    }

    @media (max-width: 575px) {
        width: 100%;
        margin-right: 0;
        float: left;
        display: table;
        top: -10px;
        border: none;

        .translate__dropdown {
            float: right;
            height: 30px;
            right: 18px;
            z-index: 1;
            position: relative;
            margin-bottom: 5px;
            top: 3px;
            display: block;

            .translate__label {
                position: relative;
                top: 3px;
            }

            .menu--lang {
                background-color: $global_color_white;
                border-left: solid 1px $global_color_primaryBlue;
                border-right: solid 1px $global_color_primaryBlue;
                border-bottom: solid 1px $global_color_primaryBlue;
                margin: 8px 0 0 10px;
                position: absolute;
                width: 160px;
                right: -1px;
            }
        }

        .translate__disclaimer {
            right: 18px;
            top: 35px;
            font-size: 14px;

            a {
                color: $global_color_white;
            }
        }
    } 
}