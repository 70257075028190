html {
	background: none;
}
body {
    margin-bottom: 0;
    color: $global_color_mostlyBlack;
} 

.navbar-default .navbar-text, .navbar-default .navbar-brand, .navbar-default .navbar-brand:focus, 
.navbar-default .navbar-brand:hover, .logohome, .seattleMayor:after, .logohome:hover { 
    color: $global_color_white;
}

//siteimprove feedback form
.sz-feedback-container {
    z-index: 1046 !important;
}

.sz-feedback-toggle-button-container {
    z-index: 1047 !important
}