/* slide-menu */
.slidemenu-right {
    width: 320px;    
    top: 0px;
    z-index: 2000;
    position: fixed;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.4s ease;
    right: -100%;
}
.slidemenu-right.slidemenu-open {    
    top: 0px;
    right: 0px;
}

#slideMenuNav {
    position: relative;

    ul {
        margin: 0px;

        li {
            width:320px; 
            padding:0px; 
            border-radius: 0px; 
            text-align: left;

            a {
                background-color: $global_color_white;
                color: $global_color_black; 
                text-decoration: none;
                font-family: $global_fontFamily_default; 
                font-size: 14px; 
                display:inline-block; 
                padding: 15px 20px;
                width:100%;
            
                &:hover {
                    background:#d7d7d7;
                    color: $global_color_black;
                }

                &.title {
                    //padding-bottom: 5px;
                }
            }
        }
        li.first {
            text-align: right;
        }
        li.last {
            height:100px;
        }
    }
    button:hover i {
        color: $global_color_primaryBlue;
    } 
    .list-group-item.active > a {
        background-color: $global_color_white;
        color: $global_color_black;

        &:hover {
            background-color: $global_color_black;
            color: $global_color_white;
        }
    }

    #seagovMenuDesktop {

        &.open {
            display: block;
        }

        &.close {
            display: none;
        }

        li {
            a {
                font-size: 20px;
                font-weight: bold;
                padding: 20px 30px;
            }
        }
    
        button { 
            border:0px; 
            background: $global_color_transparent; 
            margin:10px 10px 70px 0px; 

            i {
                color: $global_color_black;
            }
        }
    }

    #seagovMenuMobile {
    
        background-color: $global_color_lightWhite;
        height: 100%;
    
        #activeMobileHeading 
        {
            border: 0px;
            border-bottom: 1px solid #d1d1d1;
            margin-bottom: 0px;
            display: block;
    
            a {
                font-weight: bold;
                font-size: 14px;
                display: block;
            }
        } 
        #closeMobileContainer {
            background: $global_color_darkGray; 
            border: none;
        }
        #mainMenuCloseMobile {
            background: $global_color_darkGray; 
            color: $global_color_white;
            padding: 10px 20px;
            border: none;
            font-size: 16px;
            cursor: pointer;
        }

        .navItem {
            &svg {
                font-size: 24px;
            }
            &.active {
                border-bottom: 0px !important;
            }
            &.utility {

                border-bottom: solid 1px $global_color_lightWhite;

                a {
                    color: $global_color_darkGray;
                    background-color: $global_color_lightWhite;

                    &.title {
                        font-weight: bold;
                        padding-bottom: 5px;

                        &.expanded {
                            border: solid 1px #999;
                        }
                    }
                }

                .sibling {
                    a {
                        padding: 5px 20px 5px 40px;
                    }
                }

                &.button {
                    a {
                        padding-bottom: 20px;
                    }
                }
            }
        }
        #backReturn {
            background: $global_color_title;
            color: $global_color_white;
            border: 0px;
            padding: 10px 20px;
            display: none;
            
            .fa-chevron-left {
                display: none;
                margin-right: 10px;
            }
            .fa-chevron-right {
                display: none;
                margin-left: 10px;
            }
            .navPretext {
                font-size: $seaGov_fontSize_text;
            }
            #backReturnText {
                font-size: $seaGov_fontSize_text;
            }
        }
        #currentMenuContext {
            border-bottom: solid 25px $global_color_lightWhite;
        }
    }
}




.no-scroll {
    overflow: hidden;
    position: relative;
    height: 100%;
}

//portal mobile nav items
#currentMenuContextWrapper .navItem,
#currentMenuContextWrapper li.active > a {
    display: none;
}
#currentMenuContextWrapper.active #currentMenuContext > .navItem, 
#currentMenuContextWrapper li.active,
#currentMenuContextWrapper li.active > ul > li.navItem 
{
    display: block;
}
//department mobile nav items
#currentMenuContextWrapper.deptMenu #currentMenuContext > .navItem,
#currentMenuContextWrapper.portalMenu #currentMenuContext > .navItem {
    display: none;
}
#currentMenuContextWrapper.deptMenu.active #currentMenuContext > .navItem:not(.seagovHeaderLinksMobile),
#currentMenuContextWrapper.deptMenu #currentMenuContext > .navItem.active:not(.seagovHeaderLinksMobile),
#currentMenuContextWrapper.portalMenu.active #currentMenuContext > .seagovHeaderLinksMobile,
#currentMenuContextWrapper.portalMenu #currentMenuContext > .seagovHeaderLinksMobile.active {
    display: block;
}
li.child-active {
    display: block !important;
}
li.child-active > a {
    display: none !important;
}