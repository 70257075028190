/* seattle footer */
.seafooter18Wrapper {
    background: $global_color_footer;
    color: $global_color_white;
    font-size: 20px;

    #seafooter18 {
        padding-top: 19px;
    }

    #seaFooter18Col1 {
        h2 {
            font-size: 32px;
            font-weight: bold;
            font-family: $global_fontFamily_headers;
            color: $global_color_white;
            margin-top: 20px;
        }

        p {
            line-height: 34px;
        }

        .ContactComponent {
            .contactAddress {

                p {
                    line-height: 30px;
                }

                .address, .phone, .phoneAlt, .fax, .hours, .email, .contactPageLink {
                    font-weight: bold;
                }

                .phoneNumber, .phoneAltNumber {
                    pointer-events: auto;
                }

                a {
                    text-decoration: underline;
                }
            }
        }

        #col1Link0 {
            width: 280px;
            height: 35px;
            text-align: center;
            border: solid 1px $global_color_white;
            padding: 0;
            margin-bottom: 15px;
        }

        #col1Link0:hover {
            background: $global_color_white;
            cursor: pointer;
        }

        #col1Link0:hover > a {
            color: $global_color_footer;
            padding: 0;
        }

        #col1Link1, #col1Link2, #col1Link3 {
            width: 50%;
        }

        #col1Link1 {
            float: left;

            a {
                padding-right: 1px;
            }
        }

        #col1Link2, #col1Link3 {
            float: right;
        }
    }

    #seaFooter18Col1 #col1Link1:before {
        content: "\f007";
    }

    #seaFooter18Col1 #col1Link2:before {
        content: "\f1ea";
        margin-right: 10px;
    }

    #seaFooter18Col1 #col1Link3:before {
        content: "\f073";
        margin-right: 10px;
    }

    #seaFooter20Col1 {
        h2 {
            font-size: 26px;
            font-weight: bold;
            font-family: $global_fontFamily_deptTitle;
            color: $global_color_white;

            @media screen and (max-width: 575px) {
                font-size: 22px;
            }
        }

        #seaFooter20-contactSubHead {
            margin: 16px 0 4px 0;

            @media screen and (max-width: 575px) {
                margin-bottom: 0;
            }
        }

        #seaFooter20-contact1LineAddress {
            display: block;

            @media (max-width: 575px) {
                display: none;
            }
        }

        #seaFooter20-contact2LineAddress {
            display: none;

            @media (max-width: 575px) {
                display: block;
            }
        }

        #seaFooter20-col1Link0, #seaFooter20-col1Link1 {
            line-height: 32px;
            padding-bottom: 10px;
        }

        .ContactComponent {
            margin-bottom: 24px;

            .contactAddress {
                .seaFooter20-label {
                    font-weight: bold;
                }

                .seaFooter20-link {
                    text-decoration: none;
                    line-height: 34px;

                    @media (max-width: 575px) {
                        padding: 0px;
                        line-height: 24px;
                    }
                }
            }
        }

        section {
            .contactTileSocialMediaWrapper {
                margin: -13px 0 46px 0;
            }
        }

        svg {
            font-size: 25px;
            margin-right: 5px;
        }

        & .dropdown {
            border: solid 1px $global_color_white;
            margin: 25px 0 30px;
            display: table;

            > a, #reportProblemButton {
                display: block;
                padding: 3px 10px 3px 17px;
                text-decoration: none;
                font-size: 18px;
                font-weight: bold;
            }

            #reportProblemButton {
                line-height: 30px;
                background-color: inherit;
                border-width: 0px;
            }

            #reportProblemButton:focus, #reportProblemButton:hover {
                color: $global_color_footer;
                background-color: $global_color_white;
            }

            svg {
                margin-left: 10px;
                font-size: 16px;
            }

            .fa-chevron-up {
                display: none;
            }

            .fa-chevron-down {
                display: inline-block;
            }

            &.open {
                .fa-chevron-down {
                    display: none;
                }

                .fa-chevron-up {
                    display: inline-block;
                }
            }

            .dropdown-menu {
                border-radius: 3px;
                margin: 1px 0;
                padding: 0;

                a {
                    color: #333;
                    font-size: 16px;
                    font-weight: bold;
                    padding: 0px 40px 0 20px;
                    margin: 0;
                    line-height: 40px;
                }

                a:hover {
                    background-color: #f1f1f1;
                    text-decoration: underline;
                }
            }

            .columns {
                column-count: 2;
                -moz-column-count: 2;
                -webkit-column-count: 2;
                column-rule: 1px solid $global_color_black;
                column-gap: 1px;

                @media (max-width: 575px) {
                    column-count: 1;
                    -moz-column-count: 1;
                    -webkit-column-count: 1;
                }
            }
        }

        @media screen and (max-width: 575px) {
            padding: 0;
        }
    }

    #seaFooter18Col2 {
        border: solid #fff;
        border-width: 0px 1px;
        margin-top: 20px;

        @media screen and (max-width: 992px) {
            border-left-width: 0px;
        }

        @media screen and (max-width: 575px) {
            padding-bottom: 10px !important;
            border-width: 1px 0;
            margin-top: 0;
        }

        h2 {
            font-weight: bold;
            font-size: 26px;
            color: $global_color_white;
            padding-left: 5px;
            margin: 0 0 16px 0;
        }

        ul {
            list-style: none;
            padding-left: 5px;
        }

        a {
            line-height: 40px;
        }

        & #seaFooter20-col2Links {
            margin: 20px 0 0;

            li {
                margin-bottom: 8px;
                padding-bottom: 0px;
            }
        }

        svg {
            font-size: 25px;
            margin-right: 8px;
        }
    }

    #seaFooter18Col3 {

        h2, h3.tileTitle {
            font-weight: bold;
            font-size: 26px;
            color: $global_color_white;
            padding-left: 5px;
            margin-bottom: 11px;
            font-family: $global_fontFamily_deptTitle;
        }

        ul {
            list-style: none;
            padding-left: 5px;
        }

        @media screen and (max-width: 575px) {
            border-bottom: solid 1px $global_color_white;
        }
    }

    .departmentExcerpt, .departmentDisclaimer {
        padding: 30px 11px 0 11px;
        font-size: 18px;

        p {
            margin-bottom: 0;
            max-width: 850px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }

    [id^='request-lists'] ol > li:before,
    [id^='popular-lists'] ol > li:before {
        content: counter(li-counter)'.';
        background: none;
        font-weight: normal;
        font-size: 20px;
        border-radius: none;
        margin: 0;
        padding: 5px 0;
        line-height: 29.5px;
    }

    [id^='top-request-wrapper'],
    [id^='popular-page-wrapper'] {
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;

        h2, h3 {
            margin: 20px 0 7px 0;
        }

        [id^='request-lists'],
        [id^='popular-lists'] {
            line-height: 0;

            ol {
                font-size: 20px;
                margin-bottom: 10px;
            }

            ol > li a {
                margin-left: 0;
                max-width: 85%;
                width: auto;

                svg {
                    font-size: 16px;
                    margin-left: 5px;
                }
            }
        }
    }

    .contactTileSocialMediaWrapper {

        a {
            display: inline-block;
            position: relative;
            z-index: 1;

            svg {
                color: $global_color_white;
            }

            &:hover {
                color: $global_color_primaryBlue;
                // White Fill Background
                .hoverFill {
                    display: block;
                    background: #fff;
                    width: 21px;
                    height: 21px;
                    margin-top: -27px;
                    margin-left: 1px;
                    position: absolute;
                    z-index: -1;
                    border-radius: 8px;
                }
                // Circle Shapes
                .fa-wordpress ~ .hoverFill,
                .fa-pinterest ~ .hoverFill {
                    width: 24px;
                    height: 24px;
                    margin-top: -29px;
                    border-radius: 12px;
                }
                // Non-Conforming Shapes
                .fa-envelope-open-text ~ .hoverFill {
                    width: 24px;
                    height: 20px;
                    margin-top: -26px;
                    border-radius: 11px;
                }
            }
        }
    }
}

.seafooter18Wrapper a:not(.socialMediaContactItem), .seafooter18Wrapper [id^='request-lists'] ol > li a,
.seafooter18Wrapper a:not(.socialMediaContactItem), .seafooter18Wrapper [id^='popular-lists'] ol > li a {
    color: $global_color_white;
    line-height: 30px;
    padding: 5px 0;
    font-size: 20px;
}

.seafooter18Wrapper a:not(.socialMediaContactItem):hover, .seafooter18Wrapper [id^='request-lists'] ol > li a:hover,
.seafooter18Wrapper a:not(.socialMediaContactItem):hover, .seafooter18Wrapper [id^='popular-lists'] ol > li a:hover
{
    color: $global_color_footer;
    background: $global_color_white;
    text-decoration: none;
    padding: 5px 0;
}

.seafooter18Wrapper a.socialMediaContactItem:hover svg {
    color: $global_color_primaryBlue;
    background: transparent;
}

.seafooter18Wrapper.global {
    padding-bottom: 10px;
    
    //view the departments link in col 2
    #seaFooter20-col2Link3 a {
        display: block;
        margin-top: -35px;
        margin-left: 40px;
        line-height: 30px;
    }
}

.seafooter18Wrapper.department {

    #seaFooter18Col1 {

        .ContactComponent {

            .contactAddress {

                p {
                    line-height: 30px;

                    .address {
                        font-size: 100%;
                        color: $global_color_white;
                    }
                }
            }
        }
    }
    //subscribe button
    #subsribeNewsletter {
        .subscribeLinkHeader {
            margin-top: 40px;
            margin-bottom: 20px;
            font-weight: bold;
        }

        .btn-default {
            font-weight: bold;
            color: #26272d;
            background-color: #fff;
            padding: 12px;
            text-decoration: none;
            border-radius: 2px;
            font-size: 16px;
            padding: 10px 16px;
        }

        .btn-default:hover {
            color: #fff;
            background-color: #003da5;
            border-color: #fff;
        }

        .subscribeLinkFooter {
            font-style: italic;
            margin-top: 20px
        }

        svg {
            margin-left: 4px;
        }
    }
    //view the departments link in col 2
    #seaFooter20-col2Link3 a {
        display: block;
        margin-top: -35px;
        margin-left: 40px;
        line-height: 30px;
    }
}

.footerBottom18Wrapper {
    background: $global_color_footer;

    #footerBottom18 {
        color: $global_color_white;

        .copyRight {
            text-align: center;
            font-style: italic;
            padding: 70px 0 10px 0;

            .cosLogo {
                background: url("//www.seattle.gov/images/2015_img/logo_bw.png");
                background-size: auto auto;
                background-size: cover;
                width: 70px;
                height: 70px;
                margin: auto;
            }

            .text {
                padding: 10px 0;
            }
        }

        .subLinks {
            text-align: center;
            margin-bottom: 120px;

            h2 {
                color: $global_color_white;
            }

            .link {
                width: 33%;

                a {
                    color: $global_color_white;
                    padding: 5px 0;
                }

                a:hover {
                    color: $global_color_footer;
                    background: $global_color_white;
                    text-decoration: none;
                }
            }

            .link:not(:last-child) {
                border-right: solid 1px $global_color_white;
                padding: 0 10px;
            }

            .link:last-child {
                padding-left: 10px;
            }
        }
    }
}

@media (max-width: 992px) {

    .seafooter18Wrapper {

        #seaFooter18Col1 {
            padding-bottom: 20px;

            #col1Link0 {
                margin-bottom: 20px;
            }

            #col1Link2, #col1Link3 {
                padding-left: 53px;
            }
        }

        #seaFooter18Col1:after {
            border-right: none;
        }

        #seaFooter18Col3 {
            padding-left: 50px;
        }
    }
}

@media (max-width: 575px) {

    .seafooter18Wrapper {
        padding: 0 30px;
        font-size: 16px;

        a:not(.socialMediaContactItem) {
            font-size: 16px;
        }

        #seaFooter18Col1 {
            padding: 0 0 21px;

            h2 {
                font-size: 26px;
            }

            .ContactComponent {
                .contactAddress {
                    .phoneNumber, .phoneAltNumber {
                        pointer-events: auto;
                    }
                }
            }

            #col1Link0:after {
                display: block;
                position: relative;
                content: ' ';
                width: 100%;
                padding-bottom: 30px;
                border-bottom: solid 1px $global_color_white;
            }

            #col1Link0 {
                width: 100%;
                height: 35px;
            }

            #col1Link1 {
                padding-top: 20px;
                margin-top: 12px;
            }

            #col1Link1, #col1Link2, #col1Link3 {
                width: 100%;
                padding-left: 0;
            }
        }

        #seaFooter18Col1:after, #seaFooter18Col2:after, #seaFooter18Col3:after {
            border-right: none;
            border-top: solid 1px $global_color_white;
            width: 100%;
            height: 1px;
            top: 0;
            position: relative;
        }

        #seaFooter18Col1 > div:before {
            margin: -7px 10px 0 0;
        }

        #seaFooter18Col1:after {
            top: 168px;
        }

        #seaFooter18Col2:after {
            top: -267px;
        }

        #seaFooter18Col2, #seaFooter18Col3 {
            width: 100%;
            padding: 0;

            h2, h3.tileTitle {
                font-size: 22px;
            }
        }

        #seaFooter18Col2 {
            h2 {
                margin-top: 19px;
            }
        }

        #seaFooter18Col3 {
            margin-top: -3px;
            padding-bottom: 10px;

            [id^='top-request-wrapper'],
            [id^='popular-page-wrapper'] {
                margin-top: 22px;
            }
        }
    }

    .seafooter18Wrapper a, .seafooter18Wrapper [id^='request-lists'] ol > li a,
    .seafooter18Wrapper a, .seafooter18Wrapper [id^='popular-lists'] ol > li a,
    {
        font-size: 16px;
    }

    .seafooter18Wrapper.department {
        #seaFooter18Col1::after {
            top: 24px;
        }

        #seaFooter18Col2 {
            padding-bottom: 23px;

            ul {
                margin-bottom: 9px;
            }
        }

        #seaFooter18Col3 {
            [id^='top-request-wrapper'],
            [id^='popular-page-wrapper'] {
                margin-top: 21px;
            }
        }

        .departmentExcerpt {
            p {
                margin: 0 5px;
                padding-bottom: 31px;
            }
        }
    }

    .footerBottom18Wrapper {
        padding: 0 10px;

        #footerBottom18 {
            .subLinks {
                text-align: left;
                padding: 8px 0 0 0;
                margin-bottom: 0;

                h2 {
                    color: $global_color_white;
                    font-size: 22px;
                    margin: 10px 0 17px 25px;
                }

                .link {
                    width: 100%;
                    float: left;

                    a {
                        line-height: 30px;
                    }
                }


                .link:not(:last-child), .link:last-child {
                    border-right: none;
                    padding-left: 25px;
                }
            }
            
            .departmentExcerpt {
                padding: 30px 25px 0;

                &:before {
                    display: block;
                    content: ' ';
                    width: 100%;
                    height: 1px;
                    border-top: solid 1px $global_color_white;
                    margin: -8px 0 16px 0;
                }
            }

            .copyRight {
                padding-top: 20px;
                padding-bottom: 50px;
            }
        }
    }
}

.seafooter15Wrapper, #seafooter15, #departmentContactFooterWrapper {
    background: #D7D7D7;
    padding-bottom: 20px;
}
#seafooter15 {
    font-family: $global_fontFamily_default; 
    font-size: $global_fontSize_default; 
}

#seafooter15 ul { 
    margin: 0px;
    padding: 0px;
}

#seafooter15 li {
    margin:0px; padding: 0px 0px 15px; 
    list-style: none;
}    
#seafooter15> .row.borderBottomNone, #footerBottom15>.row.borderBottomNone {
    margin-right: -10px;
    margin-left: -10px;
}
#seafooter15 h2 {
    color: $global_color_black; font-family: $global_fontFamily_default; font-size:1.5em; font-weight:bold; padding:0px 0px 10px 0px;
}

#seafooter15  a {
    color: $global_color_black;
}    
            
#seafooter15 #seaFooterCol1 h2 {
    padding-bottom: 0px;
}

#seaFooterCol1 #col1Link0,
#seaFooterCol1 #col1Link1,
#seaFooterCol1 #col1Link2,
#seaFooterCol1 #col1Link3 {
    clear:both;
    padding:4px 0px 0px;
}
    #seaFooterCol1 #col1Link0 a,
    #seaFooterCol1 #col1Link1 a,
    #seaFooterCol1 #col1Link2 a,
    #seaFooterCol1 #col1Link3 a {
    display:block;
    float:left;
    margin-top:10px;
}
#seaFooterCol1 #col1Link0 a{
    margin-top:5px;
}

#seaFooterCol1 #col1Link0:before { 
    content:"\f095";
}
#seaFooterCol1 #col1Link1:before { 
    content:"\f007";
}
#seaFooterCol1 #col1Link2:before { 
    content:"\f059";
}
#seaFooterCol1 #col1Link3:before { 
    content:"\f0e0";
}
#seaFooterCol1 #col1Link0:after{
    content:'Call 206-684-2489 (CITY)';
    display:inline-block;
    font-style:italic;
    font-weight:600;
    width:85%;
}
#seaFooterCol1 >div {
    padding: 10px 0px;
}
#seaFooterCol1 > div > i.fa {
    float: left;
}
 
a#seaFooterCol1Link2, a#seaFooterCol1Link4 {
    margin-left: 40px;
}

#seafooter15 .fa-hashtag,
#seafooter15 .fa{
    font-size:30px; color: $global_color_darkGray; height:20px; width:20px; margin-left:0px;margin-right: 20px;
}
#seafooter15  .fa-hashtag{
    font-family: $global_fontFamily_default; font-weight: 900; line-height:20px;
}

#seafooter15 .footerContainer{
    padding-top:20px;
}
#seafooter15 .ContactComponent{
    padding:0px; width:100%;
}
#seafooter15 .ContactComponent h2{ 
    margin-left:82px; padding:0px;
}
#seafooter15 .ContactComponent > a {
    margin-left:0px; height:70px; width:70px; float:left; background: $global_backgroundImage_seattleLogoBW; background-size: cover; 
}
#seafooter15 .ContactComponent a span {
    display:none;
}
#seafooter15 .ContactComponent .physicalAddress {
    line-height: 18px; margin-left: 83px; margin-top: -10px;
}

.footerBottom15Wrapper, #footerBottom15 {
    background: $global_color_black; 
}

#footerBottom15{
    color: $global_color_white; min-height: 60px; font-size: 14px; font-weight:bold;padding: 15px;
}
#footerBottom15 a{
    color: $global_color_white; font-weight:bold; font-size:14px;
}

/*Services Footer TODO move to responsive2015*/
#servicesFooter .footerTiles{
    padding-bottom:40px;
}

.global #seaFooter18Col1{
    display: none !important;
}

/* about bar */
#aboutBar {
    background-color: $global_color_primaryBlue;
    padding: 4.5px 0 4.5px 0;

    ul{
        list-style: none;
        padding-left: 0px;
        position: relative;
        left: -9px;

        li { 
            float: left;
            margin: 10px 0;
            padding: 0 9px 0 9px;

            &:first-child {

                margin-right: -1px;
                border-right: solid 1px $global_color_white;
                padding-left: 0;

                a {
                    font-family: $global_fontFamily_default;
                    font-size: 32px;
                    position: relative;
                    top: 0px;
                    padding: 0px 10px 0px 10px;
                }
            }

            a {
                color: $global_color_white;
                font-family: $global_fontFamily_body;
                font-weight: 600;
                font-size: 20px;
                padding: 5px 10px;
                text-decoration:none;
                position: relative;
                top: 10px;
            }

            a:hover{
                color: $global_color_primaryBlue;
                background: $global_color_white;
                text-decoration: none;
            }

            &:last-child{
                margin-right: 0px;
            }    
        }
    }
}

@media (max-width: 991px) {
    #aboutBar {
        padding-bottom: 25px;

        ul {
            li {
                padding: 0;

                &:first-child {
                    margin-left: 10px;
                }

                a {
                    padding: 0 10px;
                }
            }
        }
    }
}