﻿#home {
    .MainColumnTabListComponent {
        .viewTabs {
            .tabsContainer {
                .tabContent {
                    padding: 30px 20px;

                    .servicesSection {
                        overflow-x: hidden;

                        .tileCounter {
                            display: none;
                        }
                    }
                }
            }
        }

        @media (max-width: 575px) {

            .viewTabs {
                margin: 0 -10px;

                .serviceList {
                    display: table;
                }

                .tileCounter {
                    display: block;
                    position: relative;

                    .currentTile {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}


/*Custom Boards & Commissions tab and accordion css to bring up to modern UI*/
#x54324 .bodyTileWrapper a {
    font-weight: bold;
}

#x54324 .bodyTileWrapper a:hover, #x54324 button.btn-link:hover {
  background: $global_color_primaryBlue;
  color: $global_color_white;
  text-decoration: none;
}

#x54324 .ui-tabs {
    padding: 0px;
    margin: 0px;
    border: 0px;
    background: $global_color_transparent;
    font-family: "Open Sans";
}

#x54324 .ui-tabs-nav, #x54324 .ui-tabs-nav * {
    padding: 0px;
    margin: 0px;
    border: 0px;
    background: $global_color_transparent;
    font-family: "Open Sans";
}

#x54324 .ui-tabs .ui-tabs-nav li {
     margin: 1px 0 0 0;
}

#x54324 .ui-tabs-nav::after {
    bottom: -5px;
    margin-top: 0;
    border-bottom: 2px solid $global_color_black;
    position: relative;
    width: 100%;
}

#x54324 .ui-tabs-nav li:not(.ui-state-active) a:hover {
    border: 2px solid $global_color_primaryBlue !important;
    background-color: $global_color_primaryBlue;
}

#x54324 .ui-tabs-nav li.ui-state-active a {
    background: $global_color_black;
}

#x54324 .ui-tabs-nav li.ui-state-active *, #x54324 .ui-tabs-nav li a:hover * {
    color: $global_color_white;
}

#x54324 .ui-tabs-nav li a {
    border: 2px solid $global_color_black;
    padding: 8px 20px;
    margin-right: 10px;
}

#x54324 .padTop.MainColumnTabListComponent .ui-tabs-nav,
#x54324 .colorBlock.MainColumnTabListComponent .ui-tabs-nav {
    margin: 0 0 40px;
    max-width: 100%;
}

#x54324 .ui-state-default, #x54324 .ui-widget-content .ui-state-default, #x54324 .ui-widget-header .ui-state-default {
    border: none;
    background: none;
    font-weight: normal;
    color: #555555;
}

#x54324 .ui-accordion .ui-state-active, #x54324 .ui-accordion .ui-widget-content .ui-state-active, #x54324 .ui-accordion .ui-widget-header .ui-state-active {
    background: $global_color_white url("images/ui-bg_glass_65_ffffff_1x400.png") repeat-x scroll 50% 50%;
    color: #212121;
    font-weight: normal;
}

#x54324 .padTop.MainColumnTabListComponent .ui-tabs .ui-tabs-nav li {
    padding-bottom: 5px !important;
}

#x54324 h2 {
    border-bottom: 1px solid $global_color_lightGray !important;
}

#x54324 h2 {
    border-bottom: medium none;
}

#x54324 .ui-accordion-header.ui-state-default {
    background: $global_color_lightWhite none repeat scroll 0 0;
}

#x54324 .ui-accordion-header {
    border: 0 none;
    border-radius: 0;
    font-size: 20px;
    font-weight: bold;
    padding: 15px 10px 15px 20px;
}

#x54324 h2 {
    color: $global_color_black;
    font-family: $global_fontFamily_deptTitle;
    font-weight: bold;
}

#x54324 .ui-accordion .ui-accordion-header .ui-accordion-header-icon {
    left: inherit;
    margin-right: 10px;
    margin-top: -8px;
    position: absolute;
    right: 0.5em;
    top: 50%;
}

#x54324 .ui-accordion-header {
    font-size: 20px;
    font-weight: bold;
}

#x54324 .ui-accordion-header button {
    background: $global_color_transparent none repeat scroll 0 0;
    border: 0 none;
    font-family: $global_fontFamily_deptTitle;
    font-size: 18px;
    font-weight: bold;
}

#x54324 .ui-accordion button {
    text-align: left;
}

#x54324 .ui-accordion-header.ui-state-hover,
#x54324 .ui-accordion-header.ui-state-hover a:hover,
#x54324 .ui-accordion-header.ui-state-hover button:hover {
  background: $global_color_lightGray;
  color: $global_color_black;
  border: 0px;
}

#x54324 .ui-accordion-content {
    max-width: 100%;
}