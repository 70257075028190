/* Notification Banner Begins */

.notificationContainer {
    padding-top: 10px;
    padding-bottom: 10px;
}
.notificationContainer ~ .notificationContainer {
    padding-top: 0px;
}

.seagovBanner { 
    position: relative;
    display: block;
    width: 100%;
    border: 0;
    padding: 1em 1em 1em 44px;
    left: 0px;
    right: 0px;
    height: -webkit-fit-content;
    color: black;
    margin: auto;

    h2 {
        font-size: 20px;
        margin: 0 0 0 38px;
    }
    p,ul {
        font-size: .875em;
        line-height: 1.5em;
        margin: 0 0 0 38px;
    }
    a {
        font-weight: bold;
    }
    svg {
        float: left;
        margin-left: -32px;
        font-size: 24px;
    }
    button {
        visibility: hidden;
        display: block;
        border: none;
        background: none;
        cursor: pointer;
        outline: none;
        position: absolute;
        bottom: 5px;
        right: 2px;

        &:focus-visible {
            border: solid 2px $global_color_black;
        }
    }
    button > svg {
        float: none;
        margin-left: 0;
        font-size: 24px;
    }

    &:before {
        content:'';
        width:100%;
        height:100%;    
        position:unset;
        left:0;
        top:0;
        background: none;
    }

    &.Normal {
        background-color: $notification_backgroundColor_normalAlert;
    }
    &.Medium-Alert {
        background-color: $notification_backgroundColor_mediumAlert;
    }
    &.High-Alert {
        background-color: $notification_backgroundColor_highAlert;
    }

    .fa-circle-exclamation {
        color: #e4002b;
        margin: 2px 5px;
        float: left;
    }
    .fa-triangle-exclamation {
        color: #9D4A02;
        margin: 2px 5px;
        float: left;
    }
    .fa-circle-info {
        color: #69b3e7;
        margin: 2px 5px;
        float: left;
    }

    @media screen and (max-width: 1023px) {
        height: 64px;
        overflow: hidden;
        padding: .5em 1em;

        p, li {
            font-size: .75em;
            line-height: 1.5em;
            width: calc(100% - 44px);
        }
        h2 {
            font-size: 15px;
            line-height: 16px;
            padding-bottom: 2px;
        }
        svg {
            font-size: 18px;
            margin-left: -24px;
        }
    }

    &.isOverflown {
        overflow: hidden;

        button {
            visibility: visible;
        }

        &.Normal {
            &:before {
                background:linear-gradient(transparent 32px, $notification_backgroundColor_normalAlert);
                position: absolute;
            }
        }
        &.Medium-Alert {
            &:before {
                background:linear-gradient(transparent 32px, $notification_backgroundColor_mediumAlert);
                position: absolute;
            }
        }
        &.High-Alert {
            &:before {
                background:linear-gradient(transparent 32px, $notification_backgroundColor_highAlert);
                position: absolute;
            }
        }
    }

    &.isExpanded {

        button {
            visibility: visible;
            bottom: -5px;
        }
    }

    .fa-sort-up {
        margin-top: 12px;
    }
}