/* Page and department Title
Department header styles need to be this explicit.
Or they'll get overidden by responsive.css & others
*/
#deptTitle {
    margin-top: 20px;
    line-height: 1.1;
    font-family: $global_fontFamily_headers;
}
        
#deptTitle a{
    font-family: $global_fontFamily_headers;
    font-weight: bold;
    font-size:2.25rem;
    color: $global_color_black;
    text-transform:none;
}

#deptTitle .director {
    font-family: $global_fontFamily_deptTitle;
    font-weight:normal;
    font-size: $global_fontSize_default;
    color: #565656;
    text-transform:none;
    display: block;

    @media (max-width: 767px) {
        line-height: 1.5;
        margin-top: 10px;
    }
}
.department div#deptTitle .director {
    margin-bottom:0px;
}


/* Breadcrumbs NEW
______________________________________________________________________*/
.titleTopNavBreadcrumbWrapper {
    /* 
        Extra class was needed to support backwards compatibility during deploy.
        Original breadcrumbs was tangled with deptTitle & topNav.
        This means a bunch of stuff that needs overriding.

        ! Move these styles to their rightful places after mvc is deployed !
    */
    &.fullWidthBreadCrumbs { 
        border-bottom: 1px solid #757575;

        #deptTitle {  /* See Above Note */
            margin: 20px 0px;

            @media (min-width: 992px) {
                margin: 20px 0px 0px 0px;
            }
            .director {
                margin-bottom: 0px;
            }
        }

        /*Department Top Nav*/
        #deptTitle.nonExec + #deptTopNav { 
            margin:10px 0px 0px 0px !important;
        }

    }
}

/* 
    OKAY! Actual Breadcrumb Code!
*/
#breadCrumbWrapper {
    @media (max-width:767px) {
        display: none;
    }

    padding: 20px 0px;
    font-size: .875em;
    color: $global_color_lightMediumGray;

    a {
        text-decoration: underline;
        color: $global_color_lightMediumGray;
        padding: 2.5px 0;
    
        &:hover{
            color: $global_color_white;
            background: $global_color_primaryBlue;

        }
        &.seaGovHome {
            max-width: 19px;
            display: inline-block;
            border-radius: 50px;
            text-align: center;

            &:hover {
                background: $global_color_white;
                .stroke {
                    fill: $global_color_primaryBlue;
                }
            }

            svg {
                width: 100%;
                max-height:18px;
                vertical-align: text-bottom;

                .stroke {
                    fill: #757575;
                }
                .fill {
                    fill: #fff;
                }
            }
        }    
    }
    .fas,
    .svg-inline--fa {
        path {
            cursor: default;
        }
        &.fa-chevron-right {
            margin: 0px 8px 0px 5px;
            font-size: 12px;
        }
        &.fa-chevron-down {
            margin-left: 3px;
        }
    }
}

/* 
    Gov Delivery
*/
.gdw_widget {
    .gdw_subscribe {
        display: table;
        width: 100%;

        #email {
            float: left;
        }

        input[type="submit"] {
            float: left;
            position: relative;
            left: 5px;
            //top: -24px;
        }
    }
}

/*FontAwesome Subsetter Bug Fix:*/
svg:not(:host).svg-inline--fa, svg:not(:root).svg-inline--fa{
    box-sizing: border-box;
}
