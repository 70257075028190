/********* MAC FIXES *********/

/*Fix font smoothing*/
body.mac *{
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
/*Fix mac font spacing*/
body.mac.notFF a.logohome {
    margin-top: 0;
}

body.mac.notFF a.logohome p {
    margin-top: -7px !important;
}

body.mac.notFF a.logohome.exec, 
body.mac.notFF .logohome.exec {
    //margin-top: 5px;
}

body.mac.notFF .logohome:before{
    //margin-top:-10px;
}

body.mac.notFF .logohome.exec:before{
    //margin-top:-1px;
}

body.mac.notFF #deptTitle {
    margin-top: 15px;
}
@media (min-width: 768px) {
        body.mac.notFF .director {
        margin-top: 0px;
        margin-bottom: 10px;
        } 
}



