/* Search */
#searchInput {
    width: 500px;
    height: 55px;
    border: none;
    font-size: 28px;
    border-radius: 0;
}
#googleSearch {
    width: 600px;
    margin: 100px auto auto;
}
#searchModal{
    padding:0px;
    border:0px; 
    background: $global_color_transparent; 
    border-radius:0px; 
    left:0px; 
    margin-left:0px; 
    width:100%;

    &.in {
        padding-right: 0 !important;
    }
}
#searchModal .modal-header{
    border: none;
    height: 52px;
}
#searchModal .modal-header .close{
    color: $global_color_white;
    opacity: 1;
    -ms-filter: progid:DGImageTransform.Microsoft.Alpha(Opacity=100);
}
#googleSearch .form-control:focus {    
    box-shadow: none;
}

#googleSearch ::-webkit-input-placeholder{
    font-size: $google_fontSize_default;
    font-weight: 700;
    background-color: $global_color_white;
    border-radius: 0;
    border: none;
    padding-top:5px;
    padding-right: 30px;
    color: $global_color_mediumGray;    
}

#googleSearch :-moz-placeholder{
    font-size: $google_fontSize_default;
    font-weight: 700;
    color: $global_color_mediumGray;
}

#googleSearch ::-moz-placeholder{
    font-size: $google_fontSize_default;
    font-weight: 700;
    color: $global_color_mediumGray;
}

#googleSearch :-ms-input-placeholder {
    font-size: $google_fontSize_default;
    font-weight: 700;
    color: $global_color_mediumGray;
}

button#searchButton {
    border-radius: 0;
    background-color: $global_color_mediumGray;
    color: $global_color_white;
    border-color: $global_color_mediumGray;
    height: 55px;
    width:55px;
}

#googleSearch #searchButton:hover{
    background: $global_color_black;
    border-color: $global_color_black;
}

#googleSearch #searchButton .fa-magnifying-glass{
    font-size: $google_fontSize_default;
    padding-left:3px;
}

#searchModal .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0px auto;
}
#searchModal .modal-content {
    height: 100%;
    border-radius: 0;
    background-color: rgb(0,0,0);
    opacity: 1;
    background-color: rgba(0,0,0, .6);
}

/* Department Search Toggle */

#googleSearchToggle {
    font-size: 14px;
    color: $global_color_white;
}
#googleSearchToggle_M {
    background-color: $global_color_darkGray;
    font-size: 15px;
    color: $global_color_white;
    text-align:left;
}
#googleSearchToggle legend, #googleSearchToggle_M legend {
    border: 0;
    margin: 0;
}
#googleSearchToggle legend span, #googleSearchToggle_M legend span {
    height: auto;
    left: -9999px;
    overflow: hidden;
    position: absolute;
    width: 100px;
}
#googleSearchToggle div.radio  {
    text-align: right;
    float: right;
    margin-top: 10px;
    font-family: $global_fontFamily_default;
}
#googleSearchToggle_M .radio  {
    margin: 5px 25px 10px
}
#googleSearchToggle .radio label {
    display:inline-block;
    padding-right:10px;
}
#googleSearchToggle_M .radio label  {
    display: block;
    width:auto;
    margin-bottom: 5px;
}
#googleSearchToggle .radio input[type="radio"], #googleSearchToggle_M .radio input[type="radio"] {
    border: 0; 
    clip: rect(0 0 0 0); 
    height: 1px; margin: -1px; 
    overflow: hidden; 
    padding: 0; 
    position: absolute; 
    width: 1px;
}
#googleSearchToggle .radio input[type="radio"] + span:before, #googleSearchToggle_M .radio input[type="radio"] + span:before  {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: -2px;/*-0.2em;*/
    border-radius: 1em;
    border: 0.2em solid $global_color_black;
    box-shadow: 0 0 0 0.15em $global_color_white;
    margin-right: 0.5em;
    transition: 0.5s ease all;
}
#googleSearchToggle .radio input[type="radio"]:checked + span:before, #googleSearchToggle_M .radio input[type="radio"]:checked + span:before {
    background: $global_color_white;
    box-shadow: 0 0 0 0.15em $global_color_white;
}
#googleSearchToggle .radio input[type="radio"]:checked + span, #googleSearchToggle_M .radio input[type="radio"]:checked + span {
    font-weight: 700	
}

@media (max-width: 768px) {
    #googleSearchToggle {
        color: #000;
    }

    #googleSearchToggle .radio input[type="radio"]:checked + span:before, #googleSearchToggle_M .radio input[type="radio"]:checked + span:before {
        background: $global_color_black;
        box-shadow: 0 0 0 0.2em $global_color_white;
    }
}