/*Shared2016.scss*/
@import "partials/global-variables";
@import "partials/shared2016-global";
@import "partials/shared2016-navBar";
@import "partials/shared2016-slideMenu";
@import "partials/shared2016-search";
@import "partials/shared2016-notificationBanner";
@import "partials/shared2016-departmentPages";
@import "partials/shared2016-footer";
@import "partials/shared2016-topMenu";
@import "partials/shared2016-macFixes";
@import "partials/shared2016-tabs-accordions";
