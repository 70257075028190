/* Navbar */
#header .navbar {
    border: none;

    @media (max-width: 767px) {
        .container {
            margin-left: -10px;
            margin-right: -10px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    @media (max-width: 575px) {
        &.vueNav {
            .container {
                margin-left: 0px;
                margin-right: 0px;
                padding-left: 10px;
                padding-right: 10px;

                #seagovNavbar {
                    margin-right: -10px;
                    margin-left: -10px;
                }
            }
        }
    }
}

#seagovNavbar {
    min-height: 60px;
}

#seagovNavbar .navbar-brand {
    display: block;
    height: inherit;
    padding: 5px 20px;
}

.navbar-brand > img {
    display: inline-block;
    width: 45px;
    padding: 10px 5px 10px 0px;
}

a.logohome, .logohome {
    padding-top: 18px;
    font-family: $global_fontFamily_tile;
    margin-left: 4px;
    font-weight: bold;
    font-size: 21px;
    color: $global_color_white;
    display: block;
}

a.logohome.exec, .logohome.exec {
    margin-top: 10px;
}

.logohome:before {
    height: 42px;
    width: 42px;
    content: '';
    display: inline-block;
    float: left;
    margin-top: -14px;
    margin-right: 10px;
    background: $global_color_white $global_backgroundImage_logoHome;
    background-size: contain;
    border-radius: 25px;
    border: 1px solid $global_color_white;
}

.logohome.exec:before {
    margin-top: -6px;
}

.seattleMayor:after {
    float: left;
    margin-left: -120px;
    margin-top: 31px;
    content: $global_title_mayorName;
    font-size: 14px;
}

.seattleMayor.qaOnly:after {
    content: 'QA Environment for Testing Purposes Only';
    
    @media (max-width: 575px) {
        content: 'QA Environment for Testing';
    }
}

.navbar-default, .navbar-default .navbar-collapse, #seagovNavbar {
    background-color: $global_color_primaryBlue;
}

#seaGovHeader .navbar-text {
    padding: 0px;
    line-height: 20px;
}

.navbar-toggle {
    display: inline-block;
}

#header {
    width: 100%;
    height: 60px;

    &.banner {
        height: 89px;
    }
}

div#seagovNavbar i:before {
    margin-right: 10px;
}

.navbar-header i {
    font-size: 18px;
}

.navbar-header i:after {
    font-family: $global_fontFamily_default;
    font-weight: bold;
    font-size: 18px;
}

.navbar-header i:before {
    font-size: $seaGov_fontSize_header;
}

/* Fontawesome v5 */
div#seagovnavbar svg:before {
    margin-right: 10px;
}

.navbar-header svg {
    margin-right: 10px;
}

/*Navbar Jumplink Fix*/
:target:before {
    content: "";
    display: block;
    height: 65px; /* fixed header height*/
    margin: -65px 0 0; /* negative fixed header height */
}

//alphaqa text next to seattle logo
#seagovNavbar span.alphaqa {
    font-size: 0.75em;
}

@media (min-width:576px) and (max-width: 991px) {
    #seagovNavbar span.alphaqa {
        font-size: .4em;
        margin-top: 16px;
        float: left;
        margin-right: -50px;
    }
}
@media (max-width: 575px) {
    #seagovNavbar span.alphaqa {
        font-size: .5em;
        margin-top: 13px;
        float: left;
        margin-right: -50px;
    }
}
@media (max-width: 767px) {
    .navbar-default .navbar-header #searchIcon {
        width: 35px !important;
    }
}

.QaBannerOn_FF {
    .navbar-default, 
    .navbar-default .navbar-collapse, 
    #seagovNavbar, 
    .navbar-default .navbar-header #supplementalLink
    {
        background-color: #8b0000 !important;
    }
    a.logohome p {
        font-size:15px !important;
        margin-top:-12px !important
      }
      a.logohome p:after {
        content:" QA Environment"
      }
      
      @media only screen and (min-width: 481px) {
        a.logohome:after {
            display: block;
            margin-top: -6px;
            font-size: 14.5px;
            content: "For Testing Purposes Only";
            font-weight: normal
        }
    } 
    
    @media only screen and (max-width: 480px) {
        a.logohome p:after {
            display: block;
            font-size: 14px !important;
            line-height: normal;
        }
        .navbar-default .navbar-header #translate_gt.navbar-text.pull-left.notranslate.mobile {
            width: auto;
        }
    }
}